import React from "react";

import { Box, Tab, Tabs } from "@mui/material";

import { FormattedMessage } from "react-intl";

import { LocalizationProvider } from "../../../contexts/PageLocaleContext";
import { tabOptions } from "../constants";
import TranslationTexts from "./BottomNavBar.translation.json";

const BottomNavBar = ({ value: tabValue, onChange: onTabChange }) => (
  <LocalizationProvider translationTexts={TranslationTexts}>
    <Box
      sx={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        bgcolor: "#191739",
      }}
    >
      <Tabs variant="fullWidth" value={tabValue} onChange={onTabChange}>
        {tabOptions.map(({ value, labelTranslationId, icon }) => (
          <Tab
            key={value}
            value={value}
            label={
              <>
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "inline" } }}
                >
                  <FormattedMessage id={labelTranslationId} />
                </Box>
                <Box
                  component="span"
                  sx={{ display: { xs: "inline", sm: "none" } }}
                >
                  <FormattedMessage id={`${labelTranslationId}-mobile`} />
                </Box>
              </>
            }
            icon={icon}
            iconPosition="start"
            sx={{ height: 48, minHeight: 48 }}
          />
        ))}
      </Tabs>
    </Box>
  </LocalizationProvider>
);

export default BottomNavBar;
