/* eslint react/no-array-index-key: 0 */
import React, { useContext } from "react";

import { Box, Button } from "@mui/material";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

import { HideScrollbar } from "../../constants/styles";
import usePreventBodyScroll from "../../hooks/usePreventBodyScroll";

// https://stackoverflow.com/questions/10744645/detect-touchpad-vs-mouse-in-javascript
function onWheel(apiObj, ev) {
  //* check for wheelDeltaY, if not check deltaX && deltaY
  const isTouchpad = ev.wheelDeltaY
    ? ev.wheelDeltaY === ev.deltaY * -3
    : Math.abs(ev.deltaX) !== 0 ||
      !Number.isInteger(ev.deltaY) ||
      Math.abs(ev.deltaY) < 75;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollPrev();
  } else if (ev.deltaY > 0) {
    apiObj.scrollNext();
  }
}

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
  return (
    <Button
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      sx={{
        color: isFirstItemVisible ? "grey.500" : "white",
        px: { xs: 0, sm: 1 },
        py: { xs: 4, sm: 8 },
        minWidth: { xs: 0, sm: "48px" },
        borderRadius: "4px 0 0 4px",
      }}
    >
      <NavigateBeforeIcon />
    </Button>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
  return (
    <Button
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      sx={{
        color: isLastItemVisible ? "grey.500" : "white",
        px: { xs: 0, sm: 1 },
        py: { xs: 4, sm: 8 },
        minWidth: { xs: 0, sm: "48px" },
        borderRadius: "0 4px 4px 0",
      }}
    >
      <NavigateNextIcon />
    </Button>
  );
};

const HorizontalItemGrid = ({
  items,
  component: Component,
  comonentProps,
  gridItemProps: { sx: gridItemSx, ...gridItemProps } = {},
}) => {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  return (
    <Box
      onMouseEnter={disableScroll}
      onMouseLeave={enableScroll}
      sx={{
        mb: 4,
        position: "relative",
        width: "100%",
        ".react-horizontal-scrolling-menu--scroll-container": {
          ...HideScrollbar,
        },
        ".react-horizontal-scrolling-menu--separator": {
          px: 1,
        },
      }}
    >
      <ScrollMenu
        onWheel={onWheel}
        LeftArrow={<LeftArrow />}
        RightArrow={<RightArrow />}
      >
        {items.map((item, idx) => (
          <Box
            key={`item-${idx + 1}`}
            sx={{
              height: { xs: "150px", sm: "300px" },
              width: { xs: "150px", sm: "300px" },
              ...gridItemSx,
              ...(item.gridItemProps?.sx ?? {}),
            }}
            {...gridItemProps}
            {...item.gridItemProps}
          >
            <Component {...item} {...comonentProps} />
          </Box>
        ))}
      </ScrollMenu>
    </Box>
  );
};

export default HorizontalItemGrid;
