import React from "react";

import Image from "next/image";

import { Box, Link, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";

import TShirtAlban from "../../../../public/img/prod/Tshirt/T-SHIRT_Alban款.png";
import TShirtIke from "../../../../public/img/prod/Tshirt/T-SHIRT_Ike款.png";
import TShirtKV from "../../../../public/img/prod/Tshirt/T-SHIRT_KV款.png";
import TShirtQAlban from "../../../../public/img/prod/Tshirt/T-SHIRT_Q版_Alban.png";
import TShirtQIke from "../../../../public/img/prod/Tshirt/T-SHIRT_Q版_Ike.png";
import TShirtQShu from "../../../../public/img/prod/Tshirt/T-SHIRT_Q版_Shu.png";
import TShirtQSonny from "../../../../public/img/prod/Tshirt/T-SHIRT_Q版_Sonny.png";
import TShirtShu from "../../../../public/img/prod/Tshirt/T-SHIRT_Shu款.png";
import TShirtSonny from "../../../../public/img/prod/Tshirt/T-SHIRT_Sonny款.png";
import CallStickAlban from "../../../../public/img/prod/call棒/call棒_Alban款.png";
import CallStickIke from "../../../../public/img/prod/call棒/call棒_Ike款.png";
import CallStickShu from "../../../../public/img/prod/call棒/call棒_Shu款.png";
import CallStickSonny from "../../../../public/img/prod/call棒/call棒_Sonny款.png";
import HandkerchiefKV from "../../../../public/img/prod/小方巾/小方巾_KV款.png";
import HandkerchiefAlban from "../../../../public/img/prod/小方巾/小方巾_alban款.png";
import HandkerchiefIke from "../../../../public/img/prod/小方巾/小方巾_ike款.png";
import HandkerchiefShu from "../../../../public/img/prod/小方巾/小方巾_shu款.png";
import HandkerchiefSonny from "../../../../public/img/prod/小方巾/小方巾_sonny款.png";
import BadgeQAlban from "../../../../public/img/prod/胸章/5.8胸章_Q版款_Alban.png";
import BadgeQIke from "../../../../public/img/prod/胸章/5.8胸章_Q版款_Ike.png";
import BadgeQShu from "../../../../public/img/prod/胸章/5.8胸章_Q版款_Shu.png";
import BadgeQSonny from "../../../../public/img/prod/胸章/5.8胸章_Q版款_Sonny.png";
import BadgeAlban from "../../../../public/img/prod/胸章/5.8胸章_人物款_Alban.png";
import BadgeIke from "../../../../public/img/prod/胸章/5.8胸章_人物款_Ike.png";
import BadgeShu from "../../../../public/img/prod/胸章/5.8胸章_人物款_Shu.png";
import BadgeSonny from "../../../../public/img/prod/胸章/5.8胸章_人物款_Sonny.png";
import BadgeHidden from "../../../../public/img/prod/胸章/5.8胸章_隱藏款.png";
import LongTowelAlban from "../../../../public/img/prod/長毛巾/長毛巾_Alban款.png";
import LongTowelIke from "../../../../public/img/prod/長毛巾/長毛巾_Ike款.png";
import LongTowelKV from "../../../../public/img/prod/長毛巾/長毛巾_KV款.png";
import LongTowelShu from "../../../../public/img/prod/長毛巾/長毛巾_Shu款.png";
import LongTowelSonny from "../../../../public/img/prod/長毛巾/長毛巾_Sonny款.png";
import HorizontalItemGrid from "../../../components/HorizontalItemGrid";
import { NEXT_PUBLIC_MARKET_URL } from "../../../constants/public-constant";
import { LocalizationProvider } from "../../../contexts/PageLocaleContext";
import { StandardCaption, StandardSpacingBox } from "./Containers";
import TranslationTexts from "./MerchandiseSection.translation.json";

const TEE_ITEMS = [
  {
    imgSrc: TShirtKV,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153535`,
  },
  {
    imgSrc: TShirtAlban,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153530`,
  },
  {
    imgSrc: TShirtQAlban,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153525`,
  },
  {
    imgSrc: TShirtIke,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153532`,
  },
  {
    imgSrc: TShirtQIke,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153528`,
  },
  {
    imgSrc: TShirtShu,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153531`,
  },
  {
    imgSrc: TShirtQShu,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153527`,
  },
  {
    imgSrc: TShirtSonny,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153529`,
  },
  {
    imgSrc: TShirtQSonny,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153519`,
  },
];
const TOWEL_ITEMS = [
  {
    imgSrc: LongTowelKV,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153533`,
    gridItemProps: {
      sx: {
        width: { xs: "300px", sm: "600px" },
      },
    },
  },
  {
    imgSrc: LongTowelAlban,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153533`,
    imageProps: {
      height: "100%",
      width: "100%",
    },
  },
  {
    imgSrc: LongTowelIke,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153533`,
    imageProps: {
      height: "100%",
      width: "100%",
    },
  },
  {
    imgSrc: LongTowelShu,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153533`,
    imageProps: {
      height: "100%",
      width: "100%",
    },
  },
  {
    imgSrc: LongTowelSonny,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153533`,
    imageProps: {
      height: "100%",
      width: "100%",
    },
  },
  {
    imgSrc: HandkerchiefKV,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153526`,
  },
  {
    imgSrc: HandkerchiefAlban,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153526`,
  },
  {
    imgSrc: HandkerchiefIke,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153526`,
  },
  {
    imgSrc: HandkerchiefShu,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153526`,
  },
  {
    imgSrc: HandkerchiefSonny,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153526`,
  },
];
const BADGE_ITEMS = [
  {
    imgSrc: BadgeAlban,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
  {
    imgSrc: BadgeQAlban,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
  {
    imgSrc: BadgeIke,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
  {
    imgSrc: BadgeQIke,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
  {
    imgSrc: BadgeShu,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
  {
    imgSrc: BadgeQShu,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
  {
    imgSrc: BadgeSonny,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
  {
    imgSrc: BadgeQSonny,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
  {
    imgSrc: BadgeHidden,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153536`,
  },
];
const CALLSTICK_ITEMS = [
  {
    imgSrc: CallStickAlban,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153534`,
  },
  {
    imgSrc: CallStickIke,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153534`,
  },
  {
    imgSrc: CallStickShu,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153534`,
  },
  {
    imgSrc: CallStickSonny,
    href: `${NEXT_PUBLIC_MARKET_URL}/product/153534`,
  },
];

const StandardMerchBox = ({
  imgSrc,
  imageProps,
  price,
  title,
  children,
  href,
}) => (
  <Box>
    <Link
      href={href}
      style={{ color: "inherit", textDecoration: "inherit" }}
      underline="none"
    >
      <Typography variant="h6" gutterBottom={!price}>
        {title}
      </Typography>
      {price && (
        <Typography color="primary" pb={1} variant="subtitle2">
          HK${price}
        </Typography>
      )}
      <Box sx={{ position: "relative" }}>
        <Image
          src={imgSrc}
          alt="merch cover"
          layout="responsive"
          objectFit="contain"
          {...imageProps}
        />
      </Box>
      {children}
    </Link>
  </Box>
);

const MerchandiseSection = (props) => (
  <Box {...props}>
    <LocalizationProvider translationTexts={TranslationTexts}>
      <Typography variant="h5" align="center">
        <FormattedMessage id="merch-title-nijisanji-crossover" />
      </Typography>
      <Typography variant="h5" align="center">
        <FormattedMessage id="merch-title-limited-merch" />
      </Typography>
      <Typography variant="h3" align="center">
        <FormattedMessage id="merch-title-order-now" />
      </Typography>
      <StandardSpacingBox />
      <Typography variant="h5" align="center" gutterBottom>
        <FormattedMessage id="merch-product-tee" />
      </Typography>{" "}
      <HorizontalItemGrid
        component={StandardMerchBox}
        gridItemProps={{
          sx: {
            width: { xs: "300px", sm: "600px" },
          },
        }}
        items={TEE_ITEMS}
        sx={{ mb: { x2: 2, sm: 4 } }}
      />
      <Typography variant="h5" align="center" gutterBottom>
        <FormattedMessage id="merch-product-towel" />
      </Typography>{" "}
      <HorizontalItemGrid
        component={StandardMerchBox}
        items={TOWEL_ITEMS}
        sx={{ mb: { x2: 2, sm: 4 } }}
      />
      <Typography variant="h5" align="center" gutterBottom>
        <FormattedMessage id="merch-product-badge" />
      </Typography>{" "}
      <HorizontalItemGrid
        component={StandardMerchBox}
        items={BADGE_ITEMS}
        sx={{ mb: { x2: 2, sm: 4 } }}
      />
      <Typography variant="h5" align="center" gutterBottom>
        <FormattedMessage id="merch-product-lightstick" />
      </Typography>{" "}
      <HorizontalItemGrid
        component={StandardMerchBox}
        items={CALLSTICK_ITEMS}
        sx={{ mb: { x2: 2, sm: 4 } }}
      />
      <StandardSpacingBox />
      <StandardCaption>
        <FormattedMessage id="merch-footnote-eligibility" />
      </StandardCaption>
      <StandardCaption>
        <FormattedMessage id="merch-footnote-in-venue-delivery" />
      </StandardCaption>
      <StandardCaption>
        <FormattedMessage id="merch-footnote-mail-orders" />
      </StandardCaption>
      <StandardCaption>
        <FormattedMessage id="merch-footnote-order-cutoff-time" />
      </StandardCaption>
    </LocalizationProvider>
  </Box>
);

export default MerchandiseSection;
