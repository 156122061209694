import React from "react";

const preventDefault = (ev) => {
  if (ev.preventDefault) {
    ev.preventDefault();
  }
  // eslint-disable-next-line no-param-reassign
  ev.returnValue = false;
};

const enableBodyScroll = () => {
  document?.removeEventListener("wheel", preventDefault, false);
};

const disableBodyScroll = () => {
  document?.addEventListener("wheel", preventDefault, {
    passive: false,
  });
};

function usePreventBodyScroll() {
  const [hidden, setHidden] = React.useState(false);

  React.useEffect(() => {
    if (hidden) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    return enableBodyScroll;
  }, [hidden]);

  const disableScroll = React.useCallback(() => setHidden(true), []);
  const enableScroll = React.useCallback(() => setHidden(false), []);
  return { disableScroll, enableScroll };
}

export default usePreventBodyScroll;
