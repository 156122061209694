import React from "react";

import LiveTvIcon from "@mui/icons-material/LiveTv";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ShopIcon from "@mui/icons-material/Shop";

const tabOptions = [
  {
    value: "ticket",
    labelTranslationId: "ticket",
    icon: <LocalActivityIcon />,
  },
  {
    value: "merch",
    labelTranslationId: "merch",
    icon: <ShopIcon />,
  },
  {
    value: "stream",
    labelTranslationId: "stream",
    icon: <LiveTvIcon />,
  },
];

export { tabOptions };

export default tabOptions;
