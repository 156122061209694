import React from "react";

import Image from "next/image";

import { Box, Grid, Link, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";

import Ticket1Image from "../../../../public/img/tix/tix1.png";
import Ticket2Image from "../../../../public/img/tix/tix2.png";
import Ticket3Image from "../../../../public/img/tix/tix3.png";
import { NEXT_PUBLIC_MARKET_URL } from "../../../constants/public-constant";
import { LocalizationProvider } from "../../../contexts/PageLocaleContext";
import { StandardCaption, StandardSpacingBox } from "./Containers";
import TranslationTexts from "./TicketSection.translation.json";

const InlineTypography = ({
  breakLine = true,
  gutterBottom = false,
  children,
  ...props
}) => (
  <Typography component="span" {...props}>
    {children}
    {breakLine && (gutterBottom ? <Box mb={0.5} /> : <br />)}
  </Typography>
);

const TicketItem = ({ href, imgSrc, children }) => (
  <Box sx={{ textAlign: "center" }}>
    <Link
      underline="none"
      color="inherit"
      href={href}
      sx={{
        //* transition underline, reference https://codepen.io/christiancroser/pen/xqrLBm
        backgroundImage: (theme) =>
          `linear-gradient(${theme.palette.primary.contrastText}, ${theme.palette.primary.contrastText})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left bottom",
        backgroundSize: "0% 0.08em",
        transition: "background-size 0.2s ease-in-out",
        ":hover": {
          backgroundSize: "100% 0.08em",
        },
      }}
    >
      <Box sx={{ width: "75%", margin: "auto" }}>
        <Image src={imgSrc} />
      </Box>
      {children}
    </Link>
  </Box>
);

const TicketSection = (props) => (
  <Box {...props} align="center">
    <LocalizationProvider translationTexts={TranslationTexts}>
      <Typography typography="h5" align="center">
        <FormattedMessage id="ticket-live-venue" />
      </Typography>
      <Typography typography="h6" align="center">
        <FormattedMessage id="ticket-live-hall" />
      </Typography>
      <StandardSpacingBox />
      <Typography variant="h5" align="center">
        <FormattedMessage id="ticket-caption-1" />
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
        <FormattedMessage id="ticket-caption-2" />
      </Typography>
      <Typography variant="h3" align="center">
        <FormattedMessage id="ticket-caption-3" />
      </Typography>
      <StandardSpacingBox />
      <Typography variant="body1" align="center">
        <FormattedMessage id="ticket-caption-5" />
      </Typography>
      <StandardSpacingBox height={0.5} />
      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <TicketItem
            href={`${NEXT_PUBLIC_MARKET_URL}/product/153515`}
            imgSrc={Ticket1Image}
          >
            <InlineTypography variant="h5">
              <FormattedMessage id="hk-ticket-title" />
            </InlineTypography>
            <InlineTypography variant="h6" gutterBottom>
              <FormattedMessage id="hk-ticket-caption-1" />
            </InlineTypography>
            <InlineTypography aligh="center">
              <FormattedMessage id="hk-ticket-caption-2" />
            </InlineTypography>
            <InlineTypography>
              <FormattedMessage id="hk-ticket-caption-3" />
            </InlineTypography>
            <InlineTypography>
              <FormattedMessage id="hk-ticket-caption-4" />
            </InlineTypography>
          </TicketItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TicketItem
            href={`${NEXT_PUBLIC_MARKET_URL}/product/153517`}
            imgSrc={Ticket2Image}
          >
            <InlineTypography variant="h5">
              <FormattedMessage id="nijisanji-ticket-title" />
            </InlineTypography>
            <InlineTypography variant="h6" gutterBottom>
              <FormattedMessage id="nijisanji-ticket-caption-1" />
            </InlineTypography>
            <InlineTypography align="center">
              <FormattedMessage id="nijisanji-ticket-caption-2" />
            </InlineTypography>
            <InlineTypography>
              <FormattedMessage id="nijisanji-live-ticket-caption-3" />
            </InlineTypography>
            <InlineTypography align="center">
              <FormattedMessage id="nijisanji-live-ticket-caption-4" />
            </InlineTypography>
          </TicketItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TicketItem
            href={`${NEXT_PUBLIC_MARKET_URL}/product/153518`}
            imgSrc={Ticket3Image}
          >
            <InlineTypography variant="h5">
              <FormattedMessage id="nijisanji-ticket-title" />
            </InlineTypography>
            <InlineTypography variant="h6" gutterBottom>
              <FormattedMessage id="nijisanji-ticket-caption-1" />
            </InlineTypography>
            <InlineTypography align="center">
              <FormattedMessage id="nijisanji-ticket-caption-2" />
            </InlineTypography>
            <InlineTypography>
              <FormattedMessage id="nijisanji-online-ticket-caption-3" />
            </InlineTypography>
          </TicketItem>
        </Grid>
      </Grid>
      <Grid align="left" textAlign="justify">
        <StandardSpacingBox />
        <StandardCaption>
          <FormattedMessage id="ticket-footnote-1-legal" />
        </StandardCaption>
        <StandardCaption>
          <FormattedMessage id="ticket-footnote-2-name-validation" />
        </StandardCaption>
        <StandardCaption>
          <FormattedMessage id="ticket-footnote-3-timezone" />
        </StandardCaption>
        <StandardCaption>
          <FormattedMessage id="ticket-footnote-4-cancellation" />
        </StandardCaption>
      </Grid>
    </LocalizationProvider>
  </Box>
);

export default TicketSection;
