import React from "react";

import { NextSeo } from "next-seo";
import { useIntl } from "react-intl";

import TrackPage from "../components/TrackPage";
import { LocalizationProvider } from "../contexts/PageLocaleContext";
import MainLayout from "../layout/MainLayout";
import { SeoBaselineConfig } from "../seo";
import VirtualFesMainView from "../views/VirtualFesMainView";
import TranslationTexts from "./index.translation.json";

export const getServerSideProps = async ({ locale, res }) => {
  res.setHeader("cache-control", "public, max-age=86400");
  return {
    props: { locale },
  };
};

const Page = ({ locale }) => {
  const intl = useIntl();
  const seo = {
    title: intl.formatMessage({ id: "seo-title" }),
    description: intl.formatMessage({ id: "seo-description" }),
    canonical: `${SeoBaselineConfig.canonical}/${locale}`,
    locale,
  };

  return (
    <>
      <TrackPage />
      <NextSeo
        {...SeoBaselineConfig}
        title={seo.title}
        canonical={seo.canonical}
        openGraph={{
          ...SeoBaselineConfig.openGraph,
          title: seo.title,
          url: seo.canonical,
          locale: seo.locale,
        }}
      />
      <VirtualFesMainView />
    </>
  );
};

Page.getLayout = (page) => (
  <MainLayout>
    <LocalizationProvider translationTexts={TranslationTexts}>
      {page}
    </LocalizationProvider>
  </MainLayout>
);

export default Page;
