import React, { forwardRef } from "react";

import Image from "next/image";

import { Box, Typography } from "@mui/material";

const StandardSpacingBox = ({ height = 1 }) => (
  <Box sx={{ height: height * 48 }} />
);

const StandardCaption = ({ children }) => (
  <Typography
    display="block"
    px={2}
    variant="caption"
    width="100%"
    textAlign="justify"
    gutterBottom
  >
    {children}
  </Typography>
);

const NoticeCaption = ({ children }) => (
  <Typography
    sx={{
      "::before": { content: '"†"' },
      display: "block",
      px: { xs: 2, sm: 6 },
      lineHeight: 1.2,
      py: 1,
      width: "100%",
      variant: { xs: "h7", sm: "h6" },
    }}
  >
    {children}
  </Typography>
);

const CompanyLogo = ({ src, alt, name, sx }) => (
  <Box sx={{ display: "flex", flexDirection: "column", ...sx }}>
    <Typography
      gutterBottom
      sx={{
        fontSize: {
          xs: "0.5rem",
          sm: "0.875rem",
        },
      }}
    >
      {name}
    </Typography>
    <Box
      sx={{
        width: {
          xs: `${(20 / src.height) * src.width.toFixed()}px`,
          sm: `${(36 / src.height) * src.width.toFixed()}px`,
        },
      }}
    >
      <Image src={src} alt={alt} layout="responsive" objectFit="contained" />
    </Box>
  </Box>
);

const ScrollContainer = forwardRef(({ children, sx, ...props }, ref) => (
  <Box
    ref={ref}
    sx={{
      position: "relative",
      overflowY: "auto",
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
));

const Section = forwardRef(({ children, sx, ...props }, ref) => (
  <Box
    ref={ref}
    sx={{
      position: "relative",

      display: "flex",
      flexDirection: "column",

      minHeight: `calc(100vh - ${48}px)`,
      paddingY: { xs: 4, sm: 8 },

      "& > *": {
        margin: "auto",
        width: "100%",
      },

      // scrollSnapAlign: "start",
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
));

export {
  StandardSpacingBox,
  StandardCaption,
  NoticeCaption,
  CompanyLogo,
  ScrollContainer,
  Section,
};
