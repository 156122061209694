import React from "react";

import Link from "next/link";

import { Box, Button, Typography } from "@mui/material";

import LiveTvIcon from "@mui/icons-material/LiveTv";
import SpeedIcon from "@mui/icons-material/Speed";

import { FormattedMessage } from "react-intl";

import { EVENT_STREAM_DESTINATION_URL } from "../../../constants/public-constant";
import { LocalizationProvider } from "../../../contexts/PageLocaleContext";
import { StandardSpacingBox } from "./Containers";
import TranslationTexts from "./StreamSection.translation.json";

const StreamSection = ({ ...props }) => (
  <Box {...props} align="center">
    <LocalizationProvider translationTexts={TranslationTexts}>
      <Typography gutterBottom textAlign="center" variant="h4">
        <FormattedMessage id="stream-title" />
      </Typography>
      <StandardSpacingBox />
      <Typography gutterBottom textAlign="center" variant="h5">
        <FormattedMessage id="stream-test-description" />
      </Typography>
      <StandardSpacingBox height={0.5} />
      <Link
        href={`${EVENT_STREAM_DESTINATION_URL}/event/a26b96c6-03cc-446a-a479-9b461c1aa9d0`}
        passHref
      >
        <Button
          startIcon={<SpeedIcon />}
          color="text"
          size="large"
          variant="outlined"
          sx={{
            fontSize: "1.5rem",
            padding: "0.7rem 2rem",
          }}
        >
          <FormattedMessage id="btn-stream-test" />
        </Button>
      </Link>
      <StandardSpacingBox height={2} />
      <Typography gutterBottom textAlign="center" variant="h5">
        <FormattedMessage id="stream-description" />
      </Typography>
      <StandardSpacingBox height={0.5} />
      <Link href="/live/7bb8df4d-1e6d-4aba-a688-5de616466df5" passHref>
        <Button
          color="primary"
          size="large"
          startIcon={<LiveTvIcon />}
          variant="contained"
          sx={{
            padding: "0.7rem 2rem",
            fontSize: "2rem",
          }}
        >
          <FormattedMessage id="btn-stream" />
        </Button>
      </Link>
    </LocalizationProvider>
  </Box>
);

export default StreamSection;
