import React from "react";

import { Box, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";

import { LocalizationProvider } from "../../../contexts/PageLocaleContext";
import { NoticeCaption, StandardSpacingBox } from "./Containers";
import TranslationTexts from "./LiveVenueNoticeSection.translation.json";

const LiveVenueNoticeSection = ({ ...props }) => (
  <Box {...props} textAlign="justify">
    <LocalizationProvider translationTexts={TranslationTexts}>
      <Typography variant="h4" align="center">
        <FormattedMessage id="live-venue-notice-title" />
      </Typography>
      <StandardSpacingBox />
      <NoticeCaption>
        <FormattedMessage id="live-venue-notice-caption-1" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage
          id="live-venue-notice-caption-2"
          defaultMessage="Error"
          description="live venue notice caption 2"
        />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="live-venue-notice-caption-3" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="live-venue-notice-caption-4" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="live-venue-notice-caption-5" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="live-venue-notice-caption-6" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="live-venue-notice-caption-7" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="live-venue-notice-caption-8" />
      </NoticeCaption>
    </LocalizationProvider>
  </Box>
);

export default LiveVenueNoticeSection;
