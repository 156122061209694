import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Container, Divider, Hidden } from "@mui/material";

import { FormattedMessage } from "react-intl";

import logo1 from "../../../public/img/co-logo/logo1.svg";
import logo2 from "../../../public/img/co-logo/logo2.svg";
import logo3 from "../../../public/img/co-logo/logo3.svg";
import logo4 from "../../../public/img/co-logo/logo4.svg";
import logo5 from "../../../public/img/co-logo/logo5.svg";
import logo6 from "../../../public/img/co-logo/logo6.svg";
import logo7 from "../../../public/img/co-logo/logo7.svg";
import { LocalizationProvider } from "../../contexts/PageLocaleContext";
import BottomNavBar from "./components/BottomNavBar";
import { CompanyLogo, Section } from "./components/Containers";
import DesktopBannerSection from "./components/DesktopBannerSection";
import LiveVenueNoticeSection from "./components/LiveVenueNoticeSection";
import MerchandiseSection from "./components/MerchandiseSection";
import MobileBannerSection from "./components/MobileBannerSection";
import StreamSection from "./components/StreamSection";
import TicketNoticeSection from "./components/TicketNoticeSection";
import TicketSection from "./components/TicketSection";
import { tabOptions } from "./constants";
import TranslationTexts from "./index.translation.json";

const DebugDivider = () =>
  process.env.NODE_ENV === "development" && <Divider />;

const VirtualFesMainView = () => {
  const sectionRef = useRef({});

  const [tabValue, setTabValue] = useState(false);
  const onTabChange = useCallback((e, value) => {
    window.scroll({
      top: (sectionRef.current?.[value]?.offsetTop ?? 0) + 48,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const elementList = Object.keys(sectionRef.current).map(
      (key) => sectionRef.current[key]
    );
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTabValue(entry?.target?.id || false);
          }
        });
      },
      {
        //* only check intersection on window middle, bottom offset for endOfPage component with 50vh
        rootMargin: "-50% 0px -49% 0px",
        threshold: 0,
      }
    );
    elementList.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      elementList.forEach((el) => {
        observer.unobserve(el);
      });
      observer.disconnect();
    };
  }, [sectionRef]);

  return (
    <>
      <Hidden implementation="css" smUp>
        <Section
          ref={(el) => {
            sectionRef.current = {
              ...sectionRef.current,
              bannerm: el,
            };
          }}
          sx={{ paddingY: 0 }}
        >
          <MobileBannerSection />
        </Section>
        <DebugDivider />
      </Hidden>
      <Hidden implementation="css" smDown>
        <Section
          ref={(el) => {
            sectionRef.current = {
              ...sectionRef.current,
              banner: el,
            };
          }}
          sx={{ paddingY: 0 }}
        >
          <DesktopBannerSection />
        </Section>
        <DebugDivider />
      </Hidden>

      <Container maxWidth="lg">
        <Section
          id={tabOptions[0].value}
          ref={(el) => {
            sectionRef.current = {
              ...sectionRef.current,
              [tabOptions[0].value]: el,
            };
          }}
        >
          <TicketSection />
        </Section>
      </Container>
      <DebugDivider />

      <Container maxWidth="lg">
        <Section
          id={tabOptions[1].value}
          ref={(el) => {
            sectionRef.current = {
              ...sectionRef.current,
              [tabOptions[1].value]: el,
            };
          }}
        >
          <MerchandiseSection />
        </Section>
      </Container>
      <DebugDivider />

      <Container maxWidth="lg">
        <Section
          id={tabOptions[2].value}
          ref={(el) => {
            sectionRef.current = {
              ...sectionRef.current,
              [tabOptions[2].value]: el,
            };
          }}
        >
          <StreamSection />
        </Section>
      </Container>
      <DebugDivider />

      <Container maxWidth="lg">
        <Section
          ref={(el) => {
            sectionRef.current = {
              ...sectionRef.current,
              misc: el,
            };
          }}
        >
          <TicketNoticeSection />
        </Section>
      </Container>
      <DebugDivider />

      <Container maxWidth="lg">
        <Section>
          <LiveVenueNoticeSection />
          <LocalizationProvider translationTexts={TranslationTexts}>
            <Box
              sx={{
                columnGap: 3,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                rowGap: 2,
                width: "100%",
                my: 8,
              }}
            >
              <CompanyLogo
                src={logo1}
                alt="flyingmilktea"
                name={<FormattedMessage id="caption-1" />}
              />
              <CompanyLogo src={logo2} alt="flyingmilktea-logo" name="&nbsp;" />
              <CompanyLogo
                src={logo3}
                alt="nijisanji-en"
                name={<FormattedMessage id="caption-2" />}
              />
              <CompanyLogo
                src={logo4}
                alt="qooapp"
                name={<FormattedMessage id="caption-3" />}
              />
              <CompanyLogo
                src={logo5}
                alt="polygon-digital"
                name={<FormattedMessage id="caption-4" />}
              />
              <CompanyLogo
                src={logo6}
                alt="battle-ham"
                name={<FormattedMessage id="caption-5" />}
              />
              <CompanyLogo
                src={logo7}
                alt="ching-mu"
                name={<FormattedMessage id="caption-6" />}
              />
            </Box>
          </LocalizationProvider>
        </Section>
      </Container>

      <BottomNavBar value={tabValue} onChange={onTabChange} />
    </>
  );
};

export default VirtualFesMainView;
