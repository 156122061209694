const HideScrollbar = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  overscrollBehaviorX: "none", //* bouncy scroll prevent on Firefox
};

export { HideScrollbar };

export default HideScrollbar;
