import React from "react";

import Image from "next/image";
import Link from "next/link";

import { Box, Link as MuiLink } from "@mui/material";

import BannerImageMobile from "../../../../public/img/banner-m.png";
import { NEXT_PUBLIC_MARKET_URL } from "../../../constants/public-constant";

const MobileBannerSection = ({ sx, ...props }) => (
  <Box
    sx={{
      position: "relative",
      width: "100%",
      "::after": {
        backgroundImage:
          "linear-gradient(to bottom, #14161700, #141617FF 100%)",
        bottom: "-1px",
        content: '""',
        height: "5%",
        left: 0,
        position: "absolute",
        pointerEvents: "none",
        width: "100%",
      },
      ...sx,
    }}
    {...props}
  >
    <Link href={`${NEXT_PUBLIC_MARKET_URL}/shop/153513`} passHref>
      <MuiLink>
        <Image
          src={BannerImageMobile}
          layout="responsive"
          objectFit="contain"
          priority
        />
      </MuiLink>
    </Link>
  </Box>
);

export default MobileBannerSection;
