import { React } from "react";

import { Box, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";

import { LocalizationProvider } from "../../../contexts/PageLocaleContext";
import { NoticeCaption, StandardSpacingBox } from "./Containers";
import TranslationTexts from "./TicketNoticeSection.translation.json";

const TicketNoticeSection = ({ locale, ...props }) => (
  <Box {...props} textAlign="justify">
    <LocalizationProvider translationTexts={TranslationTexts}>
      <Typography variant="h4" align="center">
        <FormattedMessage id="notice-title" />
      </Typography>
      <StandardSpacingBox />
      <NoticeCaption>
        <FormattedMessage id="notice-01-ticket-quota" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-02-name-validation" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-03-no-replay" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-04-one-stream-one-device" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-05-merch-eligibility" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-06-merch-in-venue-pickup" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-07-single-entry" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-08-payment-methods" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-09-transaction-fee" />
      </NoticeCaption>
      <NoticeCaption>
        <FormattedMessage id="notice-10-stream-quality" />
      </NoticeCaption>
    </LocalizationProvider>
  </Box>
);

export default TicketNoticeSection;
